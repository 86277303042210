@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf') format('truetype'), 
  url('./fonts/Inter-Regular.woff') format('woff'),
  url('./fonts/Inter-Regular.woff2') format('woff2'),
  url('./fonts/Inter-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold.ttf') format('truetype'), 
  url('./fonts/Inter-Bold.woff2') format('woff2'),
  url('./fonts/Inter-Bold.woff') format('woff'),
  url('./fonts/Inter-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}



body {
  font-family: 'Inter', 'sans-serif' !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.select {
  &__child-label {
    &--border-box {
      & > span {
        box-sizing: border-box;
      }
    }
  }
}

.full-width {
  width: 100%;
}

.full-width-inner {
  & > * {
    width: 100%;
  }
}

.small-table {
  & > table {
    font-size: 1rem;

    & th,
    & td {
      padding: 0.5rem 1rem;
    }
  }
}

.react-datepicker-popper {
  z-index: 999; // Fixing bug with datepicker being overlayed by select
}

.react-datepicker__portal .react-datepicker__navigation {
  border-width: 1rem; // Fixing bug with datepicker arrows in portal view
}

.admin-panel__sidebar {
  list-style: none;
  position: fixed;
  float: left;
  padding: 15px;
  background: #F7F7F7;
  margin: 0;
  height: 100vh;
}

.admin-panel__sidebar__item {
  padding: 15px;
  cursor: pointer;
  background: white;
  border: 1px dotted #F7F7F7;
  margin: 15px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Параметры тени */
  padding: 10px;
}

.admin-panel__sidebar__item:hover {
  color :#377a6c;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-panel__sidebar__element-settings {
  position: absolute;
  right: 5px;
}

.admin-panel__form {
  margin-top: 15px;
}

.admin-panel__sidebar-header {
  padding: 15px;
  font-weight: bold;
  font-size: 24px;
}

.admin-panel__form-header {
  margin-top: 15px;
  font-weight: bold;
  font-size: 24px;
}

/* Assume that input and textarea should be same as global configuration. */
textarea, input {
  font-family: 'Inter', sans-serif !important;
}

